<template>
	<div class="container">
		<Navbar class="navbar" @refreshFeed="refreshFeed++"/>

		<div class="grid-container home-page">
			<Feed class="feed" :key="refreshFeed" />

			<ComponentColumn class="leftColumn" :components="side.left" />
			<ComponentColumn class="rightColumn" :components="side.right" />
		</div>
	</div>
</template>

<script>
	import Navbar from "@/components/Navbar";
	import ComponentColumn from "@/components/ComponentColumn";
	import Feed from "@/components/post/Feed";

	export default {
		name: "Home",
		components: { Navbar, Feed, ComponentColumn },
		data() {
			return {
				side: {
					right: [],
					left: []
				},
				refreshFeed: 0,
				sideComponents: ['UserGroups', 'MyProfile', 'UpcomingEvents', 
					'Suggestions', 'StreamList', 'TagSuggestions']	// All side components that should be displayed
			};
		},
		mounted() {
			// Divide side components into left and right
			for (let i = 0; i < this.sideComponents.length; i++) {
				if (i % 2 === 0)
					this.side.right.push(this.sideComponents[i]);
				else
					this.side.left.push(this.sideComponents[i]);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.navbar {
		top: 0;
		width: 100%;
		z-index: 100;
	}
	.grid-container {
		display: grid;
		grid-template-columns: 15vw 20vw 30vw 20vw 15vw;
		grid-template-rows: auto;
		gap: 0px 20px;
		grid-template-areas:
			". leftColumn Feed rightColumn .";

		margin-top: 20px;

		@include respond-to('large') {
			grid-template-columns: 00vw 27vw 65vw 0vw;
			grid-template-areas: ". leftColumn Feed .";
		}
		@include respond-to('small') {
			grid-template-columns: 0vw 90vw 0vw;
			grid-template-rows: auto auto;
			grid-template-areas: ".  Feed .";
		}
	}
	.feed::v-deep {
		grid-area: Feed;

        .container {
          form {
            @include respond-to('small') {
              margin: 0;
            }
          }
		.posts article {
			@include respond-to('small') {
				margin-left: 0;
				margin-right: 0;
			}

		}
        }
	}
	.leftColumn {
		grid-area: leftColumn;

		@include respond-to('small') {
			display: none;
		}
	}
	.rightColumn {
		grid-area: rightColumn;

		@include respond-to('small') {
			display: none;
		}

		@include respond-to('medium') {
			display: none;
		}
	}
</style>
