<template>
	<label class="dark-select">
    <b-form-select class="custom-select"
     :options="options"
     :id="id"
     :value="value"
     :placeholder="label"
     :required="required"
     v-on:click="updateValue($event.target.value)"
    ></b-form-select>
	</label>
</template>

<script>
  import {BFormSelect} from "bootstrap-vue";

	export default {
		name: "DarkSelect",
		components: {BFormSelect},
		props: {
      options: {
        type: Array,
      },
      checked: {
        type: Boolean,
      },
      label: {
        type: String,
        default: ''
      },
      id: {
        type: String
      },
      value: {
        type: Boolean
      },
      required: {
        type: Boolean,
        default: false
      },
		},
    directives: {
		  'b-form-select': BFormSelect
    },
		methods: {
      updateValue: function (value) {
        this.$emit('input', value)
      }
		},
	}
</script>

<style lang="scss" scoped>
.dark-select {
  position: relative;
  &:after {
    content: '\2039';
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 1px;
    text-align: center;
    line-height: 18px;
    right: 24px;
    top: 50%;
    transform: translateY(50%) rotateZ(-90deg);
    background: transparent;
    z-index: 1;
  }
  .custom-select {
    border: none;
    background-color: var(--medium);
    color: var(--text);
    border-radius: 12px;
    appearance: none;
    padding: 10px;
    padding-right: 25px;
    width: 100%;
    &:active {
      background: #d67c1c;
      &+:after {
        transform: translateY(50%) rotateZ(0deg);
      }
    }
  }
}
</style>
