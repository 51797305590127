<template>
	<form v-if="show" @submit.prevent="handleSubmit">
		<img class="closeButton" src="@/assets/icons/close-button.svg" @click="closeForm">
		<h3>Championship table</h3>

		<h3 class="error" v-if="error.length > 0">{{ error }}</h3>
		<editable-table ref="table" :table-data.sync="tableData"></editable-table>
    <div class="form-footer">
      <p v-if="false" class="warning-text">Select a column to sort by</p>
      <input type="button" @click="saveForm" value="Save table">
    </div>
	</form>
</template>

<script>
  import EditableTable from "@/components/EditableTable";
  export default {
		name: "CTableManageForm",
    components: { EditableTable },
    props: {
			show: Boolean,
      tableData: {
			  type: Object,
        default() {
          return {
            columns: [
              {
                key: "name"
              },
              {
                key: "points"
              },
              {
                key: "3"
              },
              {
                key: "2"
              },
              {
                key: "1"
              }
            ],
            rows: [
              {
                name: "",
                points: "-",
                "3": "",
                "2": "",
                "1": ""
              },
              {
                name: "",
                points: "-",
                "3": "",
                "2": "",
                "1": ""
              },
              {
                name: "",
                points: "-",
                "3": "",
                "2": "",
                "1": ""
              },
              {
                name: "",
                points: "-",
                "3": "",
                "2": "",
                "1": ""
              }
            ],
            keySorted: {
              key: 'points',
              type: 'DESC'
            },
          }
        }
      }
		},
		data() {
			return {
				model: {
					name: "",
					description: "",
          private: false
				},
				error: ""
			};
		},
		methods: {
      saveForm() {
			  this.$emit("tableUpdated", this.tableData);
			  this.closeForm();
      },
			closeForm() {
				this.$emit("close");
			},
      clearTable() {
        this.tableData.columns = [{
                key: "name"
              },
              {
                key: "points"
              },
              {
                key: "3"
              },
              {
                key: "2"
              },
              {
                key: "1"
              }];
        this.tableData.rows = [
            {
              name: "",
              points: "-",
              "3": "",
              "2": "",
              "1": ""
            },
            {
              name: "",
              points: "-",
              "3": "",
              "2": "",
              "1": ""
            },
            {
              name: "",
              points: "-",
              "3": "",
              "2": "",
              "1": ""
            },
            {
              name: "",
              points: "-",
              "3": "",
              "2": "",
              "1": ""
            }
          ];
          this.tableData.keySorted = {
              key: 'points',
              type: 'DESC'
            },
        this.saveForm();
      },
		}
	}
</script>

<style lang="scss" scoped>
	form {
		@include side-component;
		@include box-shadow;

		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		width: 90vw;
		min-height: 200px;
		height: 90vh;
		text-align: center;
		background-color: var(--dark);
		padding: 25px;
    z-index: 10;

    .form-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: inherit;
      height: auto;
    }

		.closeButton {
			@extend .pointer;
			@extend .noselect;
			@extend .nodrag;
			position: absolute;
			top: 10px;
			left: 10px;

			&:hover {
				@include popup();
			}
		}

		h3 {
			@include side-component-title;
			margin: 0;
			margin-bottom: 10px;
		}
		.error {
			font-size: .7em;
		}

		input {
			@include text-input;

			width: 80%;
			padding: 7px;
			margin: 0 auto;
			margin-bottom: 10px;
			border: none;

			display: block;
		}
		input[type="button"] {
			@extend .pointer;

			width: 60%;
			border: none;
			background-color: var(--orange);

			&:hover {
				@include popup(1.02);
			}
		}
		.error {
			margin: 0;
			padding: 0;
			color: white;
		}
	}
</style>
