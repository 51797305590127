<template>
	<div class="main">
		<h3>ADD TO YOUR FEED</h3>
		<UserProfileCard 
			v-for="user in suggestions" 
			:key="user.id" 
			:model="user" 
		/>
		<h4 v-if="suggestions.length == 0">No users to add</h4>
	</div>
</template>

<script>
// Stores
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import UserProfileCard from "@/components/side/UserProfileCard";

export default {
	name: "Suggestions",
	components: { UserProfileCard },
	data() {
		return {
			suggestions: []
		};
	},
	async mounted() {
		await this.$api.getUserSuggestions(this.userStore.user.id, this.userStore.accessToken)
			.then((response) => {
				if (response.success === true) {
					this.suggestions = response.suggestions;
				}
			})
	},
	computed: {
		...mapStores(useUserStore)
	}
}
</script>

<style lang="scss" scoped>
	.main {
		@include side-component;

		text-align: center;
		max-width: 100%;
		padding: 10px;

		h3 {
			@include side-component-title;
		}
	}
</style>