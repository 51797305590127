<template>
	<div class="container">
		<component class="element" v-for="(component, index) in components" :key="index" :is="component" />
	</div>
</template>

<script>
	import UserGroups from "@/components/side/UserGroups";
	import Suggestions from "@/components/side/Suggestions";
	import TagSuggestions from "@/components/side/TagSuggestions";
	import UpcomingEvents from "@/components/side/UpcomingEvents";
	import StreamList from "@/components/side/StreamList";
	import MyProfile from "@/components/side/MyProfile";

	export default {
		name: "ComponentColumn",
		components: {
			UserGroups, Suggestions, TagSuggestions, UpcomingEvents, StreamList, MyProfile },
		props: {
			components: Array
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		.element {
			margin: {
				bottom: 20px;
			}
		}
	}

	.container.rightColumn {
		@include respond-to("large") {
			display: none;
		}
	}
</style>