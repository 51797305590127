<template>
	<div class="tag-suggestions">
		<header class="tag-suggestions__header">
			<h3>Tag Suggestions</h3>
		</header>
		<article v-if="!loading" class="tag-suggestions__data">
			<div v-for="tag in suggestedTags" class="tag-suggestions__data--tag">
				<span @click="onOpenTagPosts(tag)">{{ tag.name }}</span>
				<Switcher @input="subscribe(tag)"/>
			</div>
			<h2 v-if="suggestedTags.length === 0" class="tag-suggestions__data--empty">No tags for suggestion found.</h2>
		</article>
		<Loader v-else :loading="loading" />
	</div>
</template>

<script>
	// Stores
	import { mapStores } from "pinia";
	import { useUserStore } from "@/stores/UserStore";
	// Components
	import Loader from '../Loader.vue';
	import Switcher from "../Switcher.vue";

	export default {
		name: "TagSuggestions",
		components: { Loader, Switcher },
		data() {
			return {
				loading: true,
				suggestedTags: []
			}
		},
		mounted() {
			this.getTagSuggestions();
		},
		methods: {
			onOpenTagPosts(tag) {
				this.$router.push({ path: `/tag/${tag.name}`});
			},
			async subscribe(tag) {
				await this.$api.updateSubscription(this.userStore.user.id, tag.id, this.userStore.accessToken);
				this.$store.updateSubscribed(tag.id, tag.name);
			},
			async getTagSuggestions() {
				this.loading = true;
				const response = await this.$api.getTagSuggestions(this.userStore.accessToken);

				setTimeout(() => {
					if(response.success) {
						this.suggestedTags = response.tags;
					}
					
					this.loading = false;
				}, 1000);
			},
		},
		computed: {
			...mapStores(useUserStore)
		}
	}
</script>

<style lang="scss" scoped>
	.tag-suggestions {
		@include side-component;
		min-height: 130px;
		padding: 10px;

		&__header {
			text-align: center;

			h3 {
				@include side-component-title;
			}
		}

		&__data {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 10px;
			padding: 20px 0px 20px 20px;

			&--tag {
				display: flex;
				justify-content: space-between;
				align-items: center;

				span {
					cursor: pointer;
					display: inline-block;
					padding: 8px;
					border: none;
					border-radius: 15px;
					margin-right: 4px;

					font-size: .8em;

					background-color: var(--medium);
					color: var(--light);
				}
			}

			&--empty {
				font-size: 1.2rem;
				text-align: center;
				color: var(--text-darker);
			}
		}
	}
</style>