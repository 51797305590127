<template>
	<div>
		<label class="switch">
			<input type="checkbox" @click="follow" :checked="following">
			<span class="slider"></span>
		</label>
	</div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
	name: "FollowButton",
	props: {
		id: Number, // User id of a user you want to follow
		isGroup: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			text: "",
			following: false
		};
	},
	async mounted() {
		if (this.isGroup) {
			// Page: group profile
			await this.$api.isMember(this.userStore.user.id, this.id, this.userStore.accessToken)
				.then(response => {
					if (response.success === true && response.isMember === true) {
						this.following = true;
						this.text = "Leave";
					} else {
						this.text = "Join";
					}
				});
		} else {
			// Page: user profile
			await this.$api.isFollowing(this.userStore.user.id, this.id, this.userStore.accessToken)
				.then(response => {
					if (response.success === true && response.isFollowing === true) {
					this.following = true;
					this.text = "Unfollow";
					} else {
						this.text = "Follow";
					}
				})
		}
	},
	methods: {
		async follow() {
			if (this.isGroup) {
				await this.$api.joinGroup(this.userStore.user.id, this.id, this.userStore.accessToken)
					.then(response => {
						if (response.success === true) {
							this.text = response.msg;
							this.following = !this.following;
							this.$emit('refresh');
						}
					})
			} else {
				await this.$api.followUser(this.userStore.user.id, this.id, this.userStore.accessToken)
					.then(response => {
						if (response.success === true) {
							this.text = response.msg;
							this.following = !this.following;
							this.$emit('refresh');
						}
					})
			}
		}
	},
	computed: {
		...mapStores(useUserStore),
	}
}
</script>

<style lang="scss" scoped>
	.switch {
		position: relative;
		display: inline-block;
		width: 55px;
		height: 28px;

		input {
			opacity: 0;
			width: 0;
			height: 0;
		}
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ccc;
			-webkit-transition: .4s;
			transition: .4s;
			border-radius: 28px;
		}
		.slider:before {
			position: absolute;
			content: "";
			height: 24px;
			width: 24px;
			left: 2px;
			bottom: 2px;
			background-color: white;
			-webkit-transition: .4s;
			transition: .4s;
			border-radius: 50%;
		}
		input:checked + .slider {
			background-color: var(--orange);
		}
		input:focus + .slider {
			box-shadow: 0 0 1px #2196F3;
		}
		input:checked + .slider:before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}
	}
</style>
