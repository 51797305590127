<template>
	<div class="container">
		<img :src="image" @click="redirect">
		<h4 @click="redirect">{{ model.username }}</h4>
		<div class="followButton">
			<FollowButton class="right" :id="model.id" :isGroup="false" @refresh="onFollow"/>
		</div>
	</div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import FollowButton from "@/components/FollowButton";

export default {
	name: "UserProfileCard",
	components: { FollowButton },
	props: {
		model: Object
	},
	methods: {
		onFollow() {
			this.$emit('refresh')
		},
		async redirect() {
			await this.$api.getHash(this.model.id, this.userStore.accessToken)
				.then((response) => {
					if(response.success) {
						const hash = response.hash.hash_email;
						this.$router.push({ path: `/user/${this.model.username}-${hash}` });
					} else {
						this.$router.push({ path: `/` });
					}
				})
		}
	},
	computed: {
		...mapStores(useUserStore),
		image() {
			return this.$formatter.formatProfileImg(this.model.img_url);
		}
	}
}
</script>

<style lang="scss" scoped>
	.container {
		display: flex;
		align-items: center;
		padding: 5px;

		&:hover {
			background-color: var(--medium-darker);
			border-radius: 10px;
		}

		img {
			@include profile-img(55px);
			@extend .pointer;
			@extend .nodrag;
			margin-right: 10px;
		}
		h4 {
			@extend .pointer;
			@extend .noselect;
			@include dot-overflow;
			display: inline-block;
			color: var(--light);
		}
		.followButton {
			display: flex;
			align-items: center;
			margin-left: auto;
		}
	}
</style>