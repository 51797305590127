<template>
    <div class="datepicker__container">
        <div class="showDate">
          <h3 class="date">Post expiration date: <span class="showPickedDate">{{ expiration_date }}</span></h3>
          <h3 class="time">Post expiration time: <span class="showPickedDate">{{ expiration_time }}</span></h3>
        </div>
        <div class="showDatePicker">
          <b-calendar v-model="expiration_date" @context="onDateContext" class="date_picker">
          </b-calendar>
          <b-time v-model="expiration_time" @context="onTimeContext" class="time_picker"></b-time>
        </div>
    </div>
</template>

<script>
import { BCalendar, BTime } from 'bootstrap-vue';

export default {
    name: "PinnedPostDatePicker",
    components: {
        BCalendar,
        BTime
    },
    directives: {
        'b-calendar': BCalendar,
        'b-time' : BTime
    },
    data() {
        return {
            expiration_date: "",
            expiration_time: ""
        }
    },
    methods: {
      onDateContext() {
        let date = {
          expiration_date:this.expiration_date,
        }
        this.$emit("getDate", date);
      },
      onTimeContext() {
        let time = {
          expiration_time:this.expiration_time
        }
        this.$emit("getTime", time)
      }
    }
}
</script>

<style lang="scss" scoped>
.datepicker__container {
    color: white;

    @include respond-to('small') {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    .showDate {
      display: flex;
      
      & > *{
        flex-basis: 50%;
      }

    }

    .showDatePicker {
      display: flex;
      justify-content: space-between;

      & > * {
        flex-basis: 50%;
      }

      .time_picker {
        align-self: flex-start;
      }
    }
}

.showPickedDate {
    color: #fd8e00;
}

.b-calendar::v-deep {
    margin: 0;
    padding: 0; 
    display: inline-block;
    margin-bottom: 15px;

    .b-calendar-inner {
        background-color: var(--medium);
        border-radius: 5px;
        overflow: hidden;
        padding: 10px;

        .b-calendar-header {
          display: none;
          box-shadow: 0 0 0 1px var(--orange) inset;
          padding: 10px;
          border-radius: 15px;
        }
        .b-calendar-nav {
          display: flex;
          flex-direction: row;
          text-align: center;
          button {
            flex: 1 1 0px;
            line-height: 1em;
            @include button($padding: 5px, $color: var(--dark-light));
          }
        }
        .b-calendar-grid-weekdays {
          display: flex;
          flex-direction: row;
          text-align: center;
          .col {
            flex: 1 1 0px;
          }
        }
        .b-calendar-grid-body {
          .row {
            display: flex;
            flex-direction: row;
            .col {
              /*background: silver;
              padding: 3px;*/
              flex: 1 1 0px;
              text-align: center;
              padding: 4px;
              margin: 0;
            //   outline: 1px solid red;

              .btn {
                @include button($padding: 5px, $color: var(--medium-dark));
                width: 100%;
                margin: 0;
                padding: 4px 0;
                display: block;
                &.text-muted {
                  opacity: 0.4;
                }
                &.focus {
                  box-shadow: 0 0 3px var(--orange-light);
                }
                &.active {
                  @include gradient();
                }
              }
            }
          }
        }
      }
}

.b-time::v-deep {
        background-color: var(--medium);
        border-radius: 5px;
        overflow: hidden;
        padding: 5px;
        margin-top: 10px;
        .b-time-header {
          box-shadow: 0 0 0 1px var(--orange) inset;
          padding: 10px;
          border-radius: 10px;
          display: none;
        }
        [role=group] {
          display: flex;
          flex-direction: row;
          text-align: center;
          align-items: center;
          align-content: center;
          .b-form-spinbutton {
            /*display: inline-block;*/
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            .btn {
              @include button($padding: 5px, $color: var(--medium-dark));
              display: block;
            }
            output {
              display: block;
            }
          }
          .d-flex {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            svg {
              flex: 0.8 0.8;
            }
          }
        }
      }


</style>>

