<template>
  <div class="switch-outer">
    <label class="switch">
      <input type="checkbox"
             :checked="checked || value"
             :id="id"
             :value="value"
             :placeholder="label"
             :required="required"
             v-on:click="updateCheckedState($event.target.checked)">
      <span class="slider"></span>
    </label>
    <label class="switch-label" :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "Switcher",
  props: {
    checked: {
      type: Boolean,
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String
    },
    value: {
      type: Boolean
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    updateCheckedState: function (checked) {
      this.$emit('input', checked)
    }
  }
}
</script>

<style scoped lang="scss">
.switch-outer {
  padding: 0 0 10px 10px;
  .switch-label {
    margin: 0px 10px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
    vertical-align: middle;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 28px;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    input:checked + .slider {
      background-color: var(--orange);
    }

    input:focus + .slider {
      box-shadow: 0 0 2px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
}
</style>
