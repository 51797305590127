<template>
  <article v-if="!isDeleted">
    <div class="info">
      <img id="profileImg" :src="profileImg" alt="Admin Post Image" />
      <h3>{{ adminPostTitle }}</h3>
      <h4>by EFAN admins</h4>
      <div
        v-if="
          pinnedPost.id_user === this.$store.getId() ||
            this.$store.getLevel() === 10
        "
        class="post-controls"
      >
        <b-dropdown dropleft id="post-controls">
          <template #button-content>
            <b-icon-chevron-down></b-icon-chevron-down>
          </template>
          <b-dropdown-item-button @click="showDeleteModal = true">
            Remove
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              />
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </b-dropdown-item-button>
        </b-dropdown>
        <div v-bind:class="{ show: showDeleteModal }" class="remove-modal">
          <div class="box">
            <span
              >This will permanently remove the selected post. Do you wish to
              continue?</span
            >
            <div class="controls">
              <button type="button" @click="deletePost">Yes</button>
              <button type="button" @click="showDeleteModal = false">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="postContent">
      <p
        v-html="pinnedPost.text"
        v-linkified:options="{ className: 'stylingLink' }"
      ></p>
      <!-- TODO: Pinned Post image show -->
      <div class="image-modal-inner">
        <img
          v-if="hasImage"
          ref="postImage"
          id="postImg"
          :src="pinnedPost.img_url"
          @click="openPost()"
        />
      </div>

      <LinkPreview
        v-if="!hasImage"
        :title="pinnedPost.preview_title"
        :img="pinnedPost.preview_img"
        :url="pinnedPost.preview_url"
      />
    </div>

    <div class="postInfo" @click="openPost()">
      <p>{{ setComments }}</p>
      <p>{{ $formatter.formatTimeSince(pinnedPost.date_posted) }}</p>
    </div>

    <CommentForm
      :postId="pinnedPost.id"
      :postOwnerId="pinnedPost.id_user"
      @refresh="refreshComments"
      @click.native="openPost(true)"
    />
    <!-- <Comments :key="commentKey" :postId="pinnedPost.id" /> -->
  </article>
  <article class="deleted" v-else>
    This post has been successfully removed.
  </article>
</template>

<script>
// Stores
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Comments from "@/components/post/Comments";
import CommentForm from "@/components/post/CommentForm";
import LinkPreview from "@/components/post/LinkPreview";
import linkify from "vue-linkify";
import {
  BDropdown,
  BDropdownDivider,
  BDropdownItemButton,
  BIconChevronDown,
} from "bootstrap-vue";

export default {
  name: "PinnedPost",
  components: {
    Comments,
    CommentForm,
    LinkPreview,
    BDropdown,
    BDropdownDivider,
    BDropdownItemButton,
    BIconChevronDown,
  },
  directives: {
    linkified: linkify,
  },
  props: {
    pinnedPost: Object,
    isOnOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      comments: [],
      showDeleteModal: false,
      isDeleted: false,
      creator: false,
      imageModalOpen: false,
      modalImageSize: ["auto", "auto"],
    };
  },
  mounted() {
    this.getComments();
  },
  methods: {
    async deletePost() {
      const response = await this.$api.updatePost(
        {
          id: this.pinnedPost.id,
          deleted: true,
        },
        this.$store.getId()
      );

      if (response.success === true) {
        // update the state
        this.isDeleted = true;
      } else {
        // throw error
      }
    },
    async refreshComments() {
      let response = await this.$api.getComments(this.pinnedPost.id, this.userStore.accessToken);

      if (response.success === true) {
        this.comments = response.comments;
      }
    },
    async getComments() {
      let response = await this.$api.getComments(this.pinnedPost.id, this.userStore.accessToken);

      if (response.success === true) {
        this.comments = response.comments;
      } else {
        this.comments = [];
      }
    },
    openPost(clickOnInp = false) {
      if(!(this.isOnOpen)) {
        
        if(clickOnInp) {
        this.$router.push({ path: "/post/" + this.pinnedPost.id, query: { focusInput: clickOnInp } })
      } else {
        this.$router.push({ path: "/post/" + this.pinnedPost.id })
      }
      } else {
        this.$router.push({
          path: "/sign-in"
        })
      }
    },
    showHideModal() {
      this.imageModalOpen = !this.imageModalOpen;
      if (this.imageModalOpen) {
        this.modalImageSize = [
          this.$refs.postImage.clientWidth + "px",
          this.$refs.postImage.clientHeight + "px",
        ];
      } else {
        this.modalImageSize = ["auto", "auto"];
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
    adminPostTitle() {
      return this.$store.getAdminPostName();
    },
    profileImg() {
      if (this.$store.adminImgExists()) {
        let adminImg = this.$store.getAdminImg();
        if (adminImg) {
          return adminImg;
        } else {
          return require("@/assets/img/default.jpg");
        }
      } else {
        return require("@/assets/img/default.jpg");
      }
    },
    setComments() {
      return this.comments.length === 1
        ? this.comments.length + " Comment"
        : this.comments.length + " Comments";
    },
    hasImage() {
      return (
        this.pinnedPost.img_url !== null &&
        this.pinnedPost.img_url !== undefined &&
        this.pinnedPost.img_url !== ""
      );
    },
  },
};
</script>

<style lang="scss" scoped>
article {
  margin: 0 0 60px;

  text-align: center;
  color: var(--text);
  border-top: 3px solid var(--dark);
  padding-top: 10px;

  &.deleted {
    height: 5em;
    line-height: 5em;
    padding-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    color: var(--text-darker);
    animation: removedFadeout 5s ease-out both;
  }

  .remove-modal {
    z-index: 100;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--background-light);
    padding: 32px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 30px var(--background-dark);
    display: none;
    &.show {
      display: grid;
    }
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "text"
      "buttons";
    span {
      grid-area: text;
    }
    .controls {
      grid-area: buttons;
      button {
        @include button(10px, false);
        margin-top: 2em;
      }
    }
  }

  @include respond-to("small") {
    margin-left: 0;
    margin-right: 0;

    .remove-modal {
      padding: 16px;
    }
  }

  .info {
    display: grid;
    grid-template-columns: 70px auto 50px;
    grid-template-rows: auto auto;
    grid-template-areas:
      "image name controls"
      "image type bottomControls";

    padding: 8px;
    text-align: left;

    h3 {
      // User info
      @extend .pointer;
      @include dot-overflow;
      grid-area: name;
      margin: 0;
      margin-top: 5px;

      font-size: 1.3em;
    }
    h4 {
      // Type of profile
      margin: 0;
      font-size: 0.8em;
      font-weight: normal;
      grid-area: type;
    }
    #profileImg {
      grid-area: image;
      @extend .pointer;
      @extend .nodrag;
      @extend .noselect;

      @include profile-img(60px);
      @include box-shadow($color: rgba(0, 0, 0, 0.2));
    }

    .post-controls {
      grid-area: controls;
      .b-dropdown::v-deep {
        > button {
          transform: rotateZ(0deg);
          line-height: 0;
          transition: all 0.3s;
          &:hover {
            transform: rotateZ(0deg) scale(1.05, 1.05) !important;
          }
        }
        &.show {
          > button {
            transform: rotateZ(90deg) !important;
          }
        }
        button {
          @include button(10px, false, var(--medium));
        }
        .dropdown-menu {
          position: absolute;
          display: none;
          z-index: 10;
          background: var(--background-dark);
          padding: 8px 0;
          box-shadow: 3px 3px 10px var(--background-dark);
          outline: none;
          &.show {
            display: initial;
          }
          li {
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            button {
              display: flex;
              justify-content: space-between;
              width: inherit;
              margin: 0;
              background: transparent;
              border-radius: 0;
              white-space: nowrap;
              padding: 4px 10px;
              &:hover {
                background: var(--medium);
              }
              svg {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  // Post content
  .postContent {
    font-size: 1.2em;
    word-wrap: break-word;
    padding: 5px;
    height: auto;

    p {
      // Post text
      text-align: left;
      margin-top: 15px;
      margin-bottom: 25px;
    }
    #postImg {
      @include box-shadow($color: rgba(0, 0, 0, 0.3));
      max-width: 100%;
      max-height: 400px;
      border-radius: 15px;

      transition: max-height 0.3s;
      /*&:hover {
					transform: scale(1.15);
					z-index: 9999;
				}*/
    }
  }

  // Post info
  .postInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;

    p {
      color: var(--light);
      font-size: 0.9em;
      margin: 0;
      padding: 10px;
      max-width: 400px;
    }
  }

  .image-modal-outer {
    &.open {
      background: var(--background-dark);
      border-radius: 0.8em;
    }

    .image-modal {
      &.open {
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        z-index: 100;

        .image-modal-inner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          img#postImg {
            max-height: 90vh;
            max-width: 90vw;
          }
        }

        @include respond-to("small") {
          position: initial;
          height: inherit;
          width: inherit;

          .image-modal-inner {
            top: initial;
            left: initial;
            transform: initial;
            height: inherit;
            width: inherit;
            img#postImg {
              max-height: inherit;
              max-width: inherit;
              height: inherit;
              display: block;
              width: inherit;
            }
          }
        }
      }
    }
  }
}
@keyframes removedFadeout {
  0% {
    opacity: 1;
    height: 5em;
  }
  80% {
    opacity: 1;
    height: 5em;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}
</style>
