<template>
	<div class="container">
		<div class="cover" v-if="hasCoverImg">
			<img class="coverImg" :src="coverImg">
			<img class="profileImg" :src="formattedProfileImg" @click="redirect">
		</div>
		<div class="coverImgOnly" v-else>
			<img :src="formattedProfileImg" @click="redirect">
		</div>

		<h1>{{ formattedNickname }}</h1>
		<h2>{{ formattedName }}</h2>

		<h1 v-if="formattedTags.length > 0">My tags</h1>
		<h2 class="tags">{{ formattedTags }}</h2>
	</div>
</template>


<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

const isEmpty = x => (x === false || x === undefined || x === null || x === "null" || x === "");

export default {
	name: "MyProfile",
	data() {
		return {
			name: null,
			nickname: null,
			username: null,
			tags: this.$store.getSubscribed(),
			profileImg: null,
			coverImg: null
		};
	},
	mounted() {
		this.name = this.userStore.user.name;
		this.nickname = this.userStore.user.nickname;
		this.username = this.userStore.user.username;
		this.profileImg = this.userStore.user.img_url;
		this.coverImg = this.userStore.user.cover_img_url;
	},
	methods: {
		async redirect() {
			await this.$api.getHash(this.userStore.user.id, this.userStore.accessToken)
				.then((response) => {
					if(response.success) {
						const hash = response.hash.hash_email;
						this.$router.push({ path: `/user/${this.userStore.user.username}-${hash}`})
					}
				})
		}
	},
	computed: {
		...mapStores(useUserStore),
		formattedName() {
			return isEmpty(this.name) ? '' : this.name;
		},
		formattedNickname() {
			return isEmpty(this.username) ? "" : this.username;
		},	
		formattedTags() {
			return this.tags.slice(0, 5).map(x => x.name).join(", ") + ((this.tags.length > 5) ? ", ..." : "");
		},
		formattedProfileImg() {
			return this.$formatter.formatProfileImg(this.profileImg);
		},
		hasCoverImg() {
			return !isEmpty(this.coverImg);
		}
	}
}
</script>


<style lang="scss" scoped>
	.container {
		@include side-component;
		text-align: center;
		padding: 10px;

		.cover {
			position: relative;
			width: 100%;
			margin-bottom: 35px;

			.coverImg {
				width: calc(100%);
				height: 40%;
				background-color: var(--medium);
				border-radius: 15px;
			}
			.profileImg {
				@include profile-img(80px);
				@extend .pointer;
				position: absolute;
				bottom: -35px;
				left: calc(50% - 47px);
				border: 7px solid var(--dark);
			}
		}
		.coverImgOnly {
			img {
				@include profile-img(80px);
				@extend .pointer;
			}
		}
		h1 {
			color: var(--text);
			font-size: 1.1em;
		}
		h2 {
			color: var(--text-darker);
			font-size: .9em;
		}
		.tags {
			@include center;
			width: 80%;
			word-break: break-all;
		}
	}
</style>