<template>
  <div @keydown.enter.prevent.stop="onEnter($event)">
    <VueFuse ref="tagSearchInput"
      class="vue-fuse-input"
      placeholder="Search tags"
      maxlength="50"
      :search="query"
      :list="tags"
      :defaultAll="true"
      :fuse-opts="opts"
      :map-results="true"
      @fuse-results="getResults($event)" />
      
    <!-- <input
      type="text"
      v-model="query"
      placeholder="Search tags"
      @input="filter_top"
      @keydown.enter.prevent.stop="onEnter($event)"
      ref="tagSearchInput"
      maxlength="22"
    /> -->
    <img src="@/assets/icons/search.svg" />
  </div>
</template>

<script>
  import VueFuse from 'vue-fuse';

export default {
  name: "TagSearch",
  components: { VueFuse, },
  props: {
    tags: Array,
    n: {
      type: Number,
      default: 10,
    },
    trigger: Number,
    limit: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: "",
      top: [],
      results: [],
      focusedTag: null,
      opts: {
        keys: ["name"]
      }
    }
  },
  methods: {
    getResults(results) {
      this.results = results;
      this.query = this.$refs.tagSearchInput.$el.value;
      
      if(this.query !== '') {
        this.top = results.slice(0, this.n);
        this.focusedTag = this.top[0]
      } else {
        this.focusedTag = null;
        this.top = this.limit ? this.tags.slice(0, this.n) : this.tags
      }

      this.$nextTick(() => this.$refs.tagSearchInput.$el.focus())
      this.$emit("updated", this.top, this.query)
    },
    onEnter(ev) {      
      if(this.focusedTag !== null) {
        this.$emit("addToSelectedTags", this.focusedTag);
      }

      this.query = '';
      this.$refs.tagSearchInput.$el.value = null;
      this.focusedTag = null;
      this.$emit("updated", this.tags, this.query)
    }
  },
  watch: {
    trigger() {
      this.top = this.limit ? this.results.slice(0, this.n) : this.results
      this.$emit("updated", this.top, this.query)
    },
    showSearch() {
      this.$nextTick(() => this.$refs.tagSearchInput.$el.focus())
    },
  },
}
</script>

<style lang="scss" scoped>
$width: 240px;
div {
  position: relative;
  width: $width;
  @include center;
  text-align: center;

  img {
    @include white-filter;
    position: absolute;
    top: 6px;
    left: 8px;
    height: 1.6em;
  }
  .vue-fuse-input {
    @include text-input;
    @include remove-outline;
    padding: 10px;
    padding-left: 35px;
    margin-bottom: 5px;
    width: $width;
  
    background-color: transparent;
    border: 2px solid white;
    color: white;
    @include placeholder {
      color: white;
    }
  }
  
  input[type="text"] {
    box-sizing: border-box;
    @include text-input;
    @include remove-outline;
    padding: 10px;
    padding-left: 35px;
    margin-bottom: 5px;
    width: $width;
  
    background-color: transparent;
    border: 2px solid white;
    color: white;
    @include placeholder {
      color: white;
    }
  
    animation: leftToRight 0.5s;
  }
}

@keyframes leftToRight {
  0% {
    width: 10px;
  }
  100% {
    width: $width;
  }
}
</style>
