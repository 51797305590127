<template>
  <section>
    <PostForm @refresh="getFeed" />
    <PinnedPost
      v-for="pinnedPost in pinnedPosts"
      :key="pinnedPost.id"
      :pinnedPost="pinnedPost"
    />
    <div
      class="posts"
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="500"
      infinite-scroll-immediate-check="false"
      infinite-scroll-throttle-delay="500"
      infinite-scroll-disabled="noMorePosts"
    >
      <!-- v-bind has all the post data -->
      <Post 
        v-for="(post, index) in posts" 
        :key="post.id + '-' + index" 
        v-bind="post" 
        @postOpened="isPostOpened = true" 
        @closedPost="isPostOpened = false"/>
        
      <div class="center" v-if="loaderMounted">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div v-if="posts.length > 0" class="morePosts">
        <h4>{{ morePostsAlert }}</h4>
      </div>
    </div>

    <div v-if="posts.length === 0" class="noPostsContainer" @click="redirect">
      <h1>No posts found.</h1>
      <h2>Please select more tags</h2>
    </div>
  </section>
</template>

<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Post from "@/components/post/Post";
import PostForm from "@/components/post/PostForm";
import PinnedPost from "@/components/post/PinnedPost";

export default {
  name: "Feed",
  components: { Post, PostForm, PinnedPost },
  data() {
    return {
      posts: [],
      pinnedPosts: [],
      last_date: false,
      n: 6,
      ids: [],
      morePostsAlert: "",
      loaderMounted: false,
      noMorePosts: false,
      isPostOpened: false
    };
  },
  async mounted() {
    // save number of posts in local storage
    let res = await this.$api.getAdminData();
    if (res.success === true) {
      this.$store.setAdminPostName(res.data.title);
      this.$store.setAdminImage(res.data.img);
    } else {
      let adminPostTitle = this.$store.getAdminPostName();
      let adminPostImg = this.$store.getAdminImg();
      if (adminPostTitle) {
        this.$store.setAdminPostName(adminPostTitle);
      } else {
        this.$store.setAdminPostName("");
      }
      if (adminPostImg) {
        this.$store.setAdminImage(adminPostImg);
      } else {
        this.$store.setAdminImage("");
      }
    }

    this.isPostOpened = false;
    this.getFeed();
    this.getPinnedPosts();
  },
  methods: {
    async getFeed() {
      this.loaderMounted = true;
      this.ids = this.$store.getSubscribed().map((x) => x.id_tag);

      let posts = await this.$api.getFeed(
        this.ids,
        this.n,
        this.userStore.user.id,
        null,
        this.userStore.accessToken,
      );

      if (posts.success === true) {
        this.posts = posts.posts;
        this.n = this.n;
        if (posts.posts.length > 0)
          this.last_date = posts.posts[posts.posts.length - 1].date_posted;
      }
      this.loaderMounted = false;
    },
    async loadMore() {
      // Load older posts
      this.loaderMounted = true;
      let posts = await this.$api.getFeed(
        this.ids,
        this.n,
        this.userStore.user.id,
        this.last_date,
        this.userStore.accessToken,
      );

      if (posts.success === true) {
        this.posts = this.posts.concat(posts.posts);

        if (posts.posts.length > 0) {
          this.last_date = posts.posts[posts.posts.length - 1].date_posted;
        }
        else {
          this.noMorePosts = true;
          this.morePostsAlert = "No more posts found";
        }
      }
      this.loaderMounted = false;
    },

    async getPinnedPosts() {
      let pinnedPosts = await this.$api.getPinnedPosts();

      if (pinnedPosts.success === true) {
        this.pinnedPosts = [...pinnedPosts.pinnedPosts];
      } else {
        this.pinnedPosts = [];
      }
    },

    redirect() {
      this.$router.push({ path: "/tags" });
    },
  },
  watch: {
    isPostOpened(val){
      if(this.isPostOpened) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'auto';
      }
    }
  },
  computed: {
    ...mapStores(useUserStore),
  }
};
</script>

<style lang="scss" scoped>
.posts {
  width: 100%;
  margin: 0 auto;

  .morePosts {
    text-align: center;
    margin-bottom: 40px;
    button {
      @include button($gradient: true);
    }
    h4 {
      color: var(--text);
    }
  }
}
.noPostsContainer {
  @extend .pointer;
  text-align: center;
  background-color: var(--dark);
  border-radius: 15px;
  padding: 10px;

  h1 {
    color: var(--text);
    font-size: 1.8em;
  }
  h2 {
    color: var(--text-darker);
    font-size: 1.7em;
  }
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--orange);
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--orange) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
</style>
