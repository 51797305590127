<template>
	<div class="container div-headline">
		<h3>Contact Us</h3>

		<div class="contact-text">
			Get in touch with us with any question or proposition. Send us an email to
		</div>
		<div class="contact-email">
			<a href="mailto:info@efan.gg?subject=Question or proposition">info@efan.gg</a>
		</div>

		<!-- Temporary hidden Stream list
		<div class="streams">
			<div class="stream" v-for="stream in data" :key="stream.id">
				<img class="image" :src="getImgUrl(stream.img)">
				<h4 class="title">{{ stream.title }}</h4>
				<span class="type">
					<img src="@/assets/icons/web.svg">
					<h5>{{ stream.type }}</h5>
				</span>
				<span class="count">
					<img src="@/assets/icons/eye.svg">
					<h5>{{ stream.viewerCount }}</h5>
				</span>
			</div>
		</div>
		-->
	</div>
</template>


<script>
	export default {
		name: "StreamList",
		data() {
			return {
				data: [
					{id: 1, title: "Escape from Tarkov", viewerCount: 211998, type: "Public stream", img: "stream_1.jpg"},
					{id: 2, title: "Grand Theft Auto V", viewerCount: 1209, type: "Public stream", img: "stream_2.jpg"},
					{id: 3, title: "Rust", viewerCount: 6202, type: "Public stream", img: "stream_3.jpg"}
				]
			};
		},
		methods: {
			getImgUrl(name) {
				var images = require.context('@/assets/img/fake/', false, /\.jpg$/)
				return images('./' + name)
			}
		}
	}
</script>


<style lang="scss" scoped>
	.container {
		@include side-component;
		padding: 10px;

		text-align: center;

		h3 {
			@include side-component-title;
			margin-bottom: 20px;
			color: #FD6631;
		}

		.contact-text {
			color: #FD6631;
			line-height: 1.5;
		}

		.contact-email {
			margin-top: 10px;
			margin-bottom: 20px;

			a {
			text-decoration: none;
			color: #FFF;
			}
		}

		.stream {
			display: grid;
			grid-template-columns: auto 1fr 1fr;
			grid-template-rows: auto auto;
			gap: 0px 0px;
			grid-template-areas:
				"image title title"
				"image type count";

			margin-top: 10px;

			.image {
				grid-area: image;
				@include profile-img(50px);
				border-radius: 15px;
			}
			.title {
				@include dot-overflow;
				grid-area: title;
				margin: 0;
				padding-left: 10px;
				text-align: left;
			}
			.type {
				grid-area: type;
			}
			.count {
				grid-area: count;
			}

			span {
				display: flex;
				justify-content: center;
				justify-content: flex-start;

				margin-left: 15px;

				h5 {
					display: inline-block;
					margin: 0;
					margin-left: 5px;
					color: var(--text-darker);
				}
				img {
					display: inline-block;
					width: 15px;
					height: 15px;
				}
			}
		}
	}
</style>