<template>
  <div class="container" v-bind:class="{ groups: embed === false }">
    <div v-if="form" class="create">
      <img
        src="@/assets/icons/add-button.svg"
        title="Create a group"
        class="createGroupButton"
        @click="toggle"
      />
      <GroupCreateForm
        :show="show"
        @close="show = false"
        @getGroupInfo="groupCreated"
      />
    </div>

    <h3>{{ text }}</h3>

    <div class="group__searchbar" v-if="!isOnLandingPage">
      <input
        type="text"
        v-model="query"
        placeholder="Search groups"
        @input="filter_groups"
        maxlength="22"
        class="group__searchbar--input"
      />
      <img src="@/assets/icons/search.svg" class="group__searchbar--icon" />
    </div>

    <!-- Queryed groups -->
    <div v-if="query.length > 0 && allGroups.length > 0" class="groups">
      <div
        v-for="(matchedGroup, index) in filtered"
        :key="index"
        class="group"
        @click="redirect(matchedGroup.id)"
      >
        <img :src="$formatter.formatGroupImg(matchedGroup.profile_img_url)" />
        <span>
          <h4>{{ matchedGroup.name }}</h4>
          <h5
            v-if="matchedGroup.id_creator === (id !== -1 ? id : $store.getId())"
          >
            Group owner
          </h5>
          <h5 v-else>Joined group</h5>
        </span>
      </div>
    </div>

    <!-- All Groups -->
    <div v-if="groups.length > 0 && query === ''" class="groups">
      <div
        class="group"
        v-for="group in groups"
        :key="group.id"
        @click="redirect(group.id)"
      >
        <img :src="$formatter.formatGroupImg(group.profile_img_url)" />
        <span>
          <h4>{{ group.name }}</h4>
          <h5 v-if="group.id_creator === (id !== -1 ? id : $store.getId())">
            Group owner
          </h5>
          <h5 v-else>Joined group</h5>
        </span>
      </div>
    </div>
    <div v-else-if="allGroups.length === 0" class="alert">
      <img src="@/assets/icons/alert-triangle.svg" class="filter-white" />
      <h4>Not a member of any groups.</h4>
    </div>

    <div v-if="public_groups.length > 0 && query === ''" class="groups">
      <div
        class="group"
        v-for="group in public_groups"
        :key="group.id"
        @click="redirect(group.id)"
      >
        <img :src="$formatter.formatGroupImg(group.profile_img_url)" />
        <span>
          <h4>{{ group.name }}</h4>
          <h5>Public group</h5>
        </span>
      </div>
    </div>
    <div v-else-if="publicGroups && query === ''" class="alert">
      <img src="@/assets/icons/alert-triangle.svg" class="filter-white" />
      <h4>No public groups available.</h4>
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import GroupCreateForm from "@/components/group/GroupCreateForm"

export default {
  name: "UserGroups",
  components: { GroupCreateForm },
  props: {
    embed: true,
    publicGroups: false,
    id: {
      type: Number,
      default: -1,
    },
    form: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groups: [],
      public_groups: [],
      allGroups: [],
      query: "",
      show: false,
    }
  },
  mounted() {
    this.getGroups()
  },
  methods: {
    async redirect(groupId) {
      await this.$api.getTabs(groupId, this.userStore.accessToken)
        .then(response => {
          if (response.success) {
            let url =
              "/group/" +
              encodeURIComponent(groupId) +
              "/tab/" +
              encodeURIComponent(response.tabs[0].id)
            this.$router.push({ path: url })
          }
        })
    },
    async getGroups() {
      await this.$api.getUserGroups(this.id !== -1 ? this.id : this.userStore.user.id, this.userStore.accessToken)
        .then(response => {
          if (response.success === true) {
            this.groups = response.groups
          }
        })

      // public groups
      if (this.publicGroups) {
        await this.$api.getPublicGroups(this.id !== -1 ? this.id : this.userStore.user.id, this.userStore.accessToken)
        .then(response => {
          if (response.success === true) {
            this.public_groups = response.groups
          }
        })
      }
    },

    async groupCreated(groupId) {
      await this.$api.insertTab(groupId, "Main", true, 'all', this.userStore.accessToken)
        .then(response => {
          if (response.success) {
            this.redirect(groupId)
          }
        })
    },

    // OPTIONAL: Check query every .5s for new values
    filter_groups() {
      const publicGroupNames = []
      const groupNames = []

      for (const publicGroup of this.public_groups) {
        publicGroupNames.push(publicGroup)
      }

      for (const group of this.groups) {
        groupNames.push(group)
      }

      this.allGroups = [...groupNames, ...publicGroupNames]
    },
    toggle() {
      this.show = !this.show
    },
  },
  computed: {
    ...mapStores(useUserStore),
    text() {
      return this.id === this.$store.getId() ? "MY GROUPS" : "GROUPS"
    },
    filtered() {
      const matchedArray = []
      for (let i = 0; i < this.allGroups.length; i++) {
        if (
          this.allGroups[i].name.toLowerCase().match(this.query.toLowerCase())
        ) {
          matchedArray.push(this.allGroups[i])
        }
      }
      return matchedArray
    },
    isOnLandingPage() {
      return this.$route.path !== "/groups" ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
$width: 240px;

.container {
  @include side-component;
  @include scrollbars($radius: 10px, $size: 15px);

  position: relative;
  max-height: 300px;
  padding: 10px;
  text-align: center;
  overflow-y: auto;

  &.groups {
    max-height: initial;
  }

  .createGroupButton {
    @extend .pointer;
    @extend .noselect;
    @extend .nodrag;

    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
      @include popup;
    }
  }
  h3 {
    @include side-component-title;
    margin-bottom: 15px;
  }
  .groups {
    .group {
      @extend .pointer;
      display: flex;
      margin-top: 10px;
      padding: 5px;

      &:hover {
        background-color: var(--medium-darker);
        border-radius: 10px;
      }

      img {
        display: inline-block;
        @include profile-img(70px);
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 10px;
        @include dot-overflow;

        h4 {
          @include dot-overflow;
          margin: 0;
        }
        h5 {
          margin: 0;
          color: var(--medium);
        }
      }
    }
  }

  .alert {
    padding: 10px;
    h4 {
      margin: 0;
    }
    img {
      width: 30px;
    }
  }

  .group__searchbar {
    position: relative;
    width: $width;
    @include center;
    text-align: center;

    &--icon {
      @include white-filter;
      position: absolute;
      top: 6px;
      left: 8px;
      height: 1.6em;
    }

    &--input {
      box-sizing: border-box;
      @include text-input;
      @include remove-outline;
      padding: 10px;
      padding-left: 35px;
      margin-bottom: 5px;
      width: $width;

      background-color: transparent;
      border: 2px solid white;
      color: white;
      @include placeholder {
        color: white;
      }

      animation: leftToRight 0.5s;
    }
  }
}
.filter-white {
  filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%)
    contrast(102%);
}
@keyframes leftToRight {
  0% {
    width: 10px;
  }
  100% {
    width: $width;
  }
}
</style>
