<template>
  <div class="container">
    <label :for="imageUploadId">
      <img
        v-if="preview === 'profile' || preview === 'group'"
        :src="image"
        class="profilePreview"
      />

      <div
        v-else-if="preview === 'cover'"
        class="coverPreview"
        :style="{ backgroundImage: 'url(' + coverImg + ')' }"
      >
        <img src="@/assets/icons/plus-circle.svg" />
      </div>

      <img v-else src="@/assets/icons/image.svg" class="imgButton" />
    </label>
    <input
      type="file"
      accept="image/*"
      @change="uploadImage($event)"
      name="image"
      :id="imageUploadId"
    />

    <div class="info">
      <h4 v-if="imgData !== false">{{ filename }}</h4>
      <img
        v-if="imgData !== false"
        src="@/assets/icons/trash-2.svg"
        @click="removeImg"
        class="removeButton"
      />
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "ImageUpload",
  props: {
    preview: {
      type: String,
      default: "",
    },
    imageUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgData: false,
      imgUrl: false,
      imageUploadId: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, ""),
    }
  },
  methods: {
    uploadImage(event) {
      if (event.target.files && event.target.files[0].size < 5 * 1024 * 1024) {
        // File size is limited to 5MB

        // Update imgUrl
        var reader = new FileReader()
        reader.onload = (e) => {
          this.imgUrl = e.target.result
        }
        reader.readAsDataURL(event.target.files[0])

        this.imgData = new FormData()
        this.imgData.append("file", event.target.files[0])
        this.imgData.append("id", this.userStore.user.id)

        this.$emit("changed", this.imgData)
      } else {
        alert("Image size must be under 5MB.")
      }
    },
    removeImg() {
      try {
        document.querySelector("#" + this.imageUploadId).value = ""
      } catch {}
      this.imgData = false
      this.imgUrl = false

      this.$emit("changed", false)
    },
  },
  computed: {
    ...mapStores(useUserStore),
    filename() {
      if (this.imgData !== false) {
        const path = document.querySelector("#" + this.imageUploadId).value
        const filename = path.split(/(\\|\/)/g).pop()
        return filename
      } else return ""
    },
    image() {
      if (this.imgUrl === false && this.imageUrl !== "")
        return this.$formatter.formatProfileImg(this.imageUrl)
      return this.preview === "group"
        ? this.$formatter.formatGroupImg(this.imgUrl)
        : this.$formatter.formatProfileImg(this.imgUrl)
    },
    coverImg() {
      if (this.imgUrl === false && this.imageUrl !== "") return this.imageUrl
      return this.imgUrl !== false ? this.imgUrl : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 !important;

  label {
    width: 100%;
  }
  input {
    display: none;
  }
  .imgButton {
    @include white-filter;
    @extend .pointer;
    @extend .nodrag;
    display: inline-block;
    width: 40px;
    height: 40px;

    &:hover {
      @include popup(1.1);
    }
  }
  .profilePreview {
    @include profile-img(100px);
    @extend .pointer;
    @extend .nodrag;
  }
  .coverPreview {
    @extend .pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100px;
    margin-bottom: 5px;

    border-radius: 15px;
    background-color: var(--light);

    @include cover-img;

    img {
      width: 40px;
      &:hover {
        @include popup(1.05);
      }
    }
  }
  .info {
    display: flex;
    align-items: flex-end;
    h4 {
      @include dot-overflow;
      display: inline-block;
      margin: 0;
      margin-left: 4px;

      color: var(--medium);
      font-size: 0.8em;
      max-width: 200px;
    }
    .removeButton {
      @extend .pointer;
      @extend .nodrag;
      @extend .noselect;
      filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(317deg)
        brightness(103%) contrast(102%);

      &:hover {
        @include popup(1.1);
      }
    }
  }
}
</style>
