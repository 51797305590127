<template>
  <div class="container">
    <section class="selected-tag-container">
      <div v-if="postTags.length === 0">
        There is no selected tag for a post.
      </div>
      <!-- Selected buttons -->
      <div v-else v-for="tag in postTags" @click="onDeselectTag(tag)" class="selected-tag noselect pointer">
        {{ tag.name }}
      </div>
    </section>

    <TagSearch
      :class="{ hidden: !show }"
      class="searchBar"
      :trigger="registerChanges"
      :tags="tags"
      :n="tagLimit"
      :limit="showLimit"
      :showSearch="show"
      @updated="updated"
      @addToSelectedTags="newSelectedTag"
    />
    <div id="results" v-if="top.length > 0">
      <div class="tagButtons">
        <TagButton
          v-for="(tag, index) in top"
          :key="tag.id_tag + '-' + postfixRefresh"
          :ref="'tagBtn_' + tag.id_tag"
          :tag="tag"
          :query="query"
          :top="top"
          :index="index"
          :tags="selectedTags"
          :allTags="tags"
          @clicked="clicked"
        />
        <div class="showAllTags" @click="changeTagLimit()">...</div>
        <div class="img" v-if="!show && tags.length > 0" @click="setShowTrue">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-search"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>
      </div>

    </div>
    <div class="noTags" v-else>
      <h3>No tags found.</h3>
    </div>
  </div>
</template>

<script>
import TagSearch from "@/components/tag/TagSearch"
import TagButton from "@/components/tag/TagButton"

export default {
  name: "TagSearchComponent",
  components: { TagButton, TagSearch },
  props: {
    tags: Array,
    selectedTags: [],
  },
  data() {
    return {
      query: '',
      top: [],
      show: false,
      postTags: [],
      showLimit: true,
      postfixRefresh: 0
    }
  },
  methods: {
    updated(top, query) {
      this.query = query;
      this.top = top;
    },
    onDeselectTag(tag) {
      this.$emit("selected", tag.id_tag)
    },
    newSelectedTag(tag) {
      this.postfixRefresh++;
      this.$emit("selected", tag.id_tag)
    },
    setShowTrue() {
      this.show = true
    },
    clicked(id) {
      this.$emit("selected", id)
    },
    resetClicks(ids) {
      let refs = this.$refs
      ids.forEach(function(id) {
        if(refs["tagBtn_" + id][0] === undefined) return
        else refs["tagBtn_" + id][0].offSelect()
      })
    },
    changeTagLimit() {
      this.showLimit = !this.showLimit
    },
  },
  watch: {
    selectedTags(newValue) {
      this.postTags = [];
      for(const id of newValue) {
        const i = this.tags.findIndex(el => el.id_tag === id);
        this.postTags.push(this.tags[i]);
      }
    }
  },
  computed: {
    tagLimit() {
      return !this.showLimit ? this.tags.length : 5
    },
    registerChanges() {
      return this.showLimit ? 0 : 1
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0;

  .tagButtons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;
  }

  .selected-tag-container {
    font-size: .8em;
    color: var(--light);

    outline: 3px dotted var(--medium);
    border-radius: 15px;
    padding: 15px;
    margin-top: -20px;
    margin-bottom: 20px;
    
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    row-gap: 5px;

    .selected-tag {
      color: inherit;
      padding: 8px;
      margin-right: 4px;
      gap: 10px;
      border: none;
      border-radius: 15px;

      background-color: var(--orange);
    }
  }

  .hidden {
    display: none;
  }
  .img {
    @extend .pointer;
    @include button(3px 5px, false, var(--medium));
    color: var(--text-light);
    display: inline-block;
    background-color: var(--medium);
    border-radius: 15px;
    vertical-align: middle;

    svg {
      height: 1.1em;
      margin-top: 4px;
    }
  }

  .showAllTags {
    @extend .pointer;
    @include button(3px 5px, false, var(--medium));
    display: inline-block;
    padding: 8px 16px;
    border: none;
    border-radius: 15px;
    margin: 1px;
    font-size: 0.8em;
    // height: 1.2em;
  }

  .noTags {
    text-align: center;

    h3 {
      color: var(--medium);
    }
  }
}
</style>
