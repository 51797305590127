<template>
	<div @click="clicked" ref="tagBtn" :tabindex="index + 1" :class="{selected: isSelected, isOnlyTag: focusForSelection }" class="noselect pointer">
		{{ tag.name }}
	</div>
</template>

<script>
	export default {
		name: "TagButton",
		props: {
			index: Number,
			tag: Object,
			query: String,
			top: {
				type: Array,
				default: []
			},
			includeFocuseForSelection: {
				type: Boolean,
				default: true
			},
			tags: Array,
			allTags: Array
		},
		data() {
			return {
				selected: false
			};
		},
		mounted() {
			this.selected = this.tags.includes(this.tag.id_tag);
		},
		methods: {
			clicked() {
				this.$emit("clicked", this.tag.id_tag);
			},
			offSelect() {
				this.selected = false;
			}
		},
		watch: {
			tags: {
				handler(newValue, _) {
					this.selected = newValue.includes(this.tag.id_tag);
				}	
			}
		},
		computed: {
			focusForSelection() {
				if(!this.includeFocuseForSelection) {
					return false;
				}

				if(this.top.length < this.allTags.length) {
					return (this.top[0].id_tag === this.tag.id_tag);
				} else {
					return false;
				}
			},
			isSelected() {
				return this.selected;
			}			
		}
	}
</script>

<style lang="scss" scoped>
	.selected {
		background-color: var(--orange) !important;
	}

	.isOnlyTag {
		outline: 2px solid white;
		outline-offset: 1px;
	}

	div {
		display: inline-block;
		padding: 8px;
		border: none;
		border-radius: 15px;
		margin-right: 4px;

		font-size: .8em;

		background-color: var(--medium);
		color: var(--light);
	}
</style>
