<template>
  <form v-if="show" @submit.prevent="handleSubmit">
    <img
      class="closeButton"
      src="@/assets/icons/close-button.svg"
      @click="closeForm"
    />
    <h3>Create a group</h3>

    <h3 class="error" v-if="error.length > 0">{{ error }}</h3>
    <input
      v-model="model.name"
      type="text"
      placeholder="Group name"
      minlength="6"
      maxlength="30"
    />
    <input
      v-model="model.description"
      type="text"
      placeholder="Group description"
      minlength="5"
      maxlength="250"
    />
    <Switcher
      id="setPrivate"
      label="Private group"
      v-model="model.private"
    ></Switcher>
    <input type="submit" value="Create group" />
  </form>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Switcher from "@/components/Switcher"

export default {
  name: "GroupCreateForm",
  components: { Switcher },
  props: {
    show: Boolean,
  },
  data() {
    return {
      model: {
        name: "",
        description: "",
        private: false,
      },
      error: "",
    }
  },
  methods: {
    async handleSubmit() {
      // Validation
      if (this.model.name.length < 6 || this.model.name.length > 30) {
        this.error = "Name has to include between 6 and 30 characters."
        return
      } else if (this.model.description.length > 250) {
        this.error = "Description has to include less than 250 characters."
        return
      } else {
        await this.$api.insertGroup(
          this.model.name,
          this.model.description,
          this.model.private,
          this.userStore.user.id,
          this.userStore.accessToken
        ).then(response => {
          if (response.success === true) {
            this.model.name = ""
            this.model.description = ""
            this.model.private = false
            this.error = ""
  
            this.$emit("getGroupInfo", response.group_id);
            this.closeForm();
          } else {
            this.error = response.msg
          }
        });
      }
    },
    closeForm() {
      this.$emit("close")
    },
  },
  computed: {
    ...mapStores(useUserStore),
  }
}
</script>

<style lang="scss" scoped>
form {
  @include side-component;
  @include box-shadow;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  width: 600px;
  min-height: 400px;
  height: auto;
  text-align: center;
  background-color: var(--dark);
  padding: 25px;

  @include respond-to("small") {
    width: 95vw;
  }

  .closeButton {
    @extend .pointer;
    @extend .noselect;
    @extend .nodrag;
    position: absolute;
    top: 10px;
    left: 10px;

    &:hover {
      @include popup();
    }
  }

  h3 {
    @include side-component-title;
    margin: 0;
    margin-top: 70px;
    margin-bottom: 30px;
  }
  .switch-outer {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1em;
  }
  .error {
    font-size: 0.7em;
  }

  input {
    @include text-input;

    width: 80%;
    padding: 7px;
    margin: 0 auto;
    margin-bottom: 10px;
    border: none;

    display: block;
  }
  input[type="submit"] {
    @extend .pointer;

    width: 60%;
    border: none;
    background-color: var(--orange);

    &:hover {
      @include popup(1.02);
    }
  }
  .error {
    margin: 0;
    padding: 0;
    color: white;
  }
}
</style>
