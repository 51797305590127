<template>
  <div class="table-outer">
    <div class="controls">
      <button type="button" @click="addNewRow">Add Player +</button>
      <button type="button" @click="addNewColumn">Add Game +</button>
      <button type="button" @click="clearTable">New table</button>
    </div>
    <table>
      <thead>
      <tr>
        <th class="empty"></th>
        <th class="edit-button" v-for="(thItem, columnId) in tableData.columns" @click="removeColumn(columnId)" :key="thItem.key">
          <b-icon-x></b-icon-x>
        </th>
      </tr>
      <tr>
        <th class="empty"></th>
        <th v-for="(thItem, columnId) in tableData.columns"
            @click="sortIfAllowed(thItem.key)"
            @dblclick="makeEditable(-1, thItem.key)"
            :key="thItem.key">
          <span v-if="itemInEditing.key === thItem.key && itemInEditing.row === -1">
              <input :ref="'ref-1' + '_' + thItem.key"
                type="text" 
                :disabled="thItem.key === 'points' || thItem.key === 'name'" 
                :value="human(tableData.columns[columnId].key)"
                @keydown.enter.prevent="closeEditableHeader($event)" />
          </span>
          <span>{{ thItem.label ? thItem.label : human(thItem.key) }}</span>
          <div v-if="tableData.keySorted.key === thItem.key">
            <b-icon-sort-down-alt v-if="tableData.keySorted.type === 'ASC'" class="sort-icon"></b-icon-sort-down-alt>
            <b-icon-sort-down v-else class="sort-icon"></b-icon-sort-down>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(trItem, trId) in tableData.rows" :key="trItem.key">
          <td class="edit-button" @click="removeRow(trId)">
            <b-icon-x></b-icon-x>
          </td>
          <td v-for="tdItem in tableData.columns" :key="tdItem.key" @click="makeEditable(trId, tdItem.key)" id="settingTd">
            <span v-if="itemInEditing.key === tdItem.key && itemInEditing.row === trId">
              <input :ref="'ref' + trId + '_' + tdItem.key"
                type="text"
                v-model="tableData.rows[trId][tdItem.key]"
                :disabled = "tdItem.key === 'points'"
                id="settingInp"
                @keydown.enter.prevent="closeEditable"
                @blur.prevent="closeEditable"
                @keydown.up="moveEditable('up')"
                @keydown.down="moveEditable('down')"
                @keydown.left="moveEditable('left')"
                @keydown.right="moveEditable('right')"
              >
            </span>
            <span>{{ trItem[tdItem.key] }}</span>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import { BTable, BIcon, BIconSortDown, BIconSortDownAlt, BIconX } from "bootstrap-vue";

export default {
  name: "EditableTable",
  components: { BTable, BIcon, BIconSortDown, BIconSortDownAlt, BIconX },
  props: {
    tableData: {
      type: Object,
      default() {
        return {
          columns: [],
          rows: [],
          keySorted: {
            key: "points",
            type: 'DESC'
          },
        }
      },
    }
  },
  data: () => {
    return {
      itemInEditing: {
        row: 0,
        key: null
      }
    }
  },
  methods: {
    /**
     * Create a human readable version of key.
     * @param value
     * @returns {string}
     */
    human: (value) => {
      let words = value.match(/[A-Za-z0-9][a-z0-9]*/g) || [];
      return words.map((word) => {
        return word.charAt(0).toUpperCase() + word.substring(1);
      }).join(" ");
    },
    /**
     * Create a key from input text.
     * @param value
     * @returns {string}
     */
    underscore: (value) => {
      let words = value.match(/[A-Za-z0-9][a-z0-9]*/g) || [];
      return words.map((word) => {
        return word.charAt(0).toLowerCase() + word.substring(1);
      }).join("_");
    },
    /**
     * Sort and close if current field isn being edited.
     * @param key
     */
    sortIfAllowed(key) {
      if(key !== 'points') return;
      if (!(this.itemInEditing.row === -1 && this.itemInEditing.key === key)) {
        this.closeEditable();
        this.keySort("points");
      }
    },
    /**
     * Sort objects by key.
     * @param key
     * @returns {boolean}
     */
    keySort(key) {
      if (key) {
        let kSort = (a, b) => {
          if (!isNaN(a[key]) && !isNaN(b[key])) {
            return Number(a[key]) - Number(b[key]);
          } else {
            let fa = a[key].toString().toLowerCase(),
                fb = b[key].toString().toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }
        };
        this.tableData.rows.sort(kSort);
        if (this.tableData.keySorted.key !== key || (this.tableData.keySorted.key === key && this.tableData.keySorted.type === 'DESC')) {
          // this.tableData.keySorted.key = key;
          this.tableData.keySorted.type = 'ASC';
        } else {
          // this.tableData.keySorted.key = key;
          this.tableData.keySorted.type = 'DESC';
          this.tableData.rows.reverse();
        }
        this.returnData();
      }
    },
    /**
     * Refresh the sorted data.
     */
    resort() {
      this.tableData.keySorted.type = this.tableData.keySorted.type === 'ASC' ? 'DESC' : 'ASC';
      this.keySort('points');
    },
    /**
     * Select and focus on created input.
     */
    focusAndSelect() {
      // executes after dom update
      this.$nextTick(() => {
        // Creates an array of refs it dosen't input duplicates
        let input = this.$refs['ref' + this.itemInEditing.row + '_' + this.itemInEditing.key][0];
        input.focus();
        input.setSelectionRange(0, input.value.length);          
      });
        
    },
    /**
     * Overlay an input on table data.
     */
    // Executes with double click
    makeEditable(row, key) {
      if (this.itemInEditing.key !== key || this.itemInEditing.row !== row) {
        this.itemInEditing.key = key;
        this.itemInEditing.row = row;
        this.focusAndSelect();
      }
    },
    /**
     * Add move by keys function.
     */
    moveEditable(direction) {
      if (this.itemInEditing.key) {
        let getColumnPos = (key) => {
          return this.tableData.columns.findIndex((el) => {
            return el.key === key;
          });
        };
        this.$nextTick(() => {
          let input = this.$refs['ref' + this.itemInEditing.row + '_' + this.itemInEditing.key][0];
          switch (direction) {
            case 'up':
              if (input.selectionStart === 0 && this.itemInEditing.row > 0
                  && input.selectionStart === input.selectionEnd) {
                this.itemInEditing.row -= 1;
                setTimeout(() => this.focusAndSelect(), 10);
              }
              break;
            case 'down':
              if (input.selectionStart === input.value.length && this.itemInEditing.row < this.tableData.rows.length-1
                  && input.selectionStart === input.selectionEnd) {
                this.itemInEditing.row += 1;
                setTimeout(() => this.focusAndSelect(), 10);
              }
              break;
            case 'left':
              if (input.selectionStart === 0 && getColumnPos(this.itemInEditing.key) > 0
                  && input.selectionStart === input.selectionEnd) {
                this.itemInEditing.key = this.tableData.columns[getColumnPos(this.itemInEditing.key) -1].key;
                setTimeout(() => this.focusAndSelect(), 10);
              }
              break;
            case 'right':
              if (input.selectionStart === input.value.length
                  && getColumnPos(this.itemInEditing.key) < this.tableData.columns.length-1
                  && input.selectionStart === input.selectionEnd) {
                this.itemInEditing.key = this.tableData.columns[getColumnPos(this.itemInEditing.key) +1].key;
                setTimeout(() => this.focusAndSelect(), 10);
              }
              break;
            default:
              return;
          }
        });
      }
    },
    /**
     * Remove input on table data.
     */
    closeEditable(e) {
      if (e) {
        e.preventDefault();
      }
      this.itemInEditing.key = null;
      this.itemInEditing.row = 0;
      this.sumOfRows();
      this.returnData();
    },
    /**
     * Remove input on table data header and update all columns.
     */
    closeEditableHeader(e) {
      let currKey = this.itemInEditing.key,
          keyIndex = this.tableData.columns.findIndex((el) => {return el.key === currKey}),
          updatedKey = this.underscore(e.target.value);

      // check for the same key exists
      let intersect = this.tableData.columns.findIndex((el) => {return el.key === updatedKey});

      if (intersect === -1) {
        // update the key
        this.tableData.columns[keyIndex].key = this.underscore(e.target.value);
        // update the row objects
        this.tableData.rows.map(function (o) {
          // ES6
          delete Object.assign(o, {[updatedKey]: o[currKey] })[currKey];
          // ES5
          /*Object.defineProperty(o, updatedKey, Object.getOwnPropertyDescriptor(o, currKey));
          delete o[currKey];*/
        });
        this.itemInEditing.key = null;
        this.itemInEditing.row = 0;
      }

      
      this.returnData();
    },
    /**
     * Creates a new empty row.
     */
    addNewRow() {
      let newObject = {};
      this.tableData.columns.forEach((column) => {
        if(column.key === "points") {
          newObject[column.key] = '-';
        } else {
          newObject[column.key] = "";
        }
      });
      let numOfRows = this.tableData.columns.length - 1;
      this.tableData.rows.splice(numOfRows ,0,newObject);
      this.returnData();
    },
    /**
     * Creates a new empty column.
     */
    addNewColumn() {
      let newObject = {}
      if(this.tableData.columns.length <= 2) {
        newObject = {
          key : "1"
        }
      } else {
        if(isNaN(+this.tableData.columns['2'].key)) {
          newObject = {
            key: "c " + (this.tableData.columns.length - 1)
          };
        } else {
          newObject = {
            key: "" + (+this.tableData.columns['2'].key + 1)
          };
        }
      }
      
      this.tableData.columns.splice(2,0,this.checkForDuplicate(newObject.key, newObject));
      this.tableData.rows.forEach((row) => {
        row[this.checkForDuplicate(newObject).key] = '';
      });
      this.returnData();
    },

    checkForDuplicate(newColumnKey, newObj) {
      const checkedKey = newColumnKey;
      const checkedObj = { ...newObj };

      for(const column of this.tableData.columns) {
        if(checkedKey === column.key) {
          checkedObj.key = "" + (+this.tableData.columns['2'].key + 1)
        }
      }
      return checkedObj;
    },
    /**
     * Remove selected row.
     */
    removeRow(rowId) {
      let ok = confirm("This action is irreversible, Continue?");
      if (ok) {
        this.tableData.rows.splice(rowId, 1);
        this.returnData();
      }
    },
    /**
     * Remove selected column.
     */
    removeColumn(columnId) {
      let ok = confirm("This action is irreversible, Continue?");
      let key = this.tableData.columns[columnId].key;

      if(key === 'points' || key === 'name') {
        alert("You can't delete this column from table");
        return
      } 

      if (ok) {
          this.tableData.rows.forEach((row) => {
              delete row[key];
          });
          this.tableData.columns.splice(columnId, 1);
          this.sumOfRows();
          this.returnData();
        }
    },
    /**
     * Clear the table data
     */
    clearTable() {
      let clear = confirm('All table data will be deleted. Continue?');
      if (clear) {
        this.tableData.columns = [{key : "name"},{key: "points"}];
        this.tableData.rows = [{name: "", points: "-"}, {name: "", points: "-"}, {name: "", points: "-"}];
        this.returnData();
      }
    },
    returnData() {
      this.$emit('update:tableData', this.tableData);
    },
    sumOfRows() {
        let startingNumber = 0;
          for(const row of this.tableData.rows) {
            for(const property in row) {
              if(property !== 'points' && property !== 'name') {
                if(+row[property] > 0 || +row[property] < 0) {
                  startingNumber += +row[property];
                }
              }
              row['points'] = startingNumber;
            }
            startingNumber = 0;
          }
      }
  }
  
  
}
</script>

<style scoped lang="scss">
.controls {
  button {
    @include button(10px, false);
  }
}
#pasteInput {
  background: black;
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  color: white;
  padding: 30vh 0;
  resize: none;
}

.table-outer {
  overflow: auto;
}

table::v-deep {
  width: 100%;

  @media (min-width: 992px) {
        thead th {
          position: sticky;
          top: 0;
          z-index: 1;
        }
    
        // new
        tbody th {
          position: relative;
        }
    
        // new
        thead th:nth-child(3) {
          position: sticky;
          left: 160px;
          z-index: 2;
          // background-color: red;
        }
    
        // new
        thead th:nth-child(2) {
          position: sticky;
          left: 0;
          z-index: 2;
        }
    
        // new
        tbody td:nth-child(3) {
          position: sticky;
          left: 160px;
          z-index: 2;
        }
    
        // new
        tbody td:nth-child(2) {
          position: sticky;
          left: 0;
          z-index: 2;
        }
    
        // new
        tbody th {
          position: sticky;
          left: 0;
          z-index: 1;
        }
    }

  thead, tbody {
    tr {
      overflow: scroll;
      &:nth-child(2n) {
        td {
          padding: 5px;
          background: var(--medium-dark);
        }
      }

      th, td {
        padding: 5px;
        background: var(--background);

        position: relative;
        overflow: hidden;
        input[type=text] {
          position: absolute;
          height: 98%;
          width: 100%;
          left: 0;
          top: 0;
          background: var(--text-darker);
          border: none;
          text-align: center;
        }

        &.empty {
          background: transparent;
          width: 15px;
        }


        &.edit-button {
          height: 15px;
          max-width: 25px;
          background: var(--orange-dark);
          font-size: 0.9em;
          padding: 0;
          cursor: pointer;
          &:hover {
            background: var(--orange);
          }
        }
      }
      th {
        background: var(--background-light);
        position: relative;
        >*{
          display: inline-flex;
        }
        &[aria-sort="ascending"] {
          &:after {
            content: "A";
          }
        }
        &[aria-sort="descending"] {
          &:after {
            content: "D";
          }
        }
        .sort-icon {
          margin-left: 10px;
        }
        .sr-only {
          display: none;
        }

      
      }
    }
  }
}

#settingTd, #settingInp {
  min-width: 150px;
}
</style>
