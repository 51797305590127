<template>
  <div class="container">
    <form @submit.prevent="handleSubmit">
      <div class="text">
        <textarea
          :rows="rows"
          v-model="model.text"
          v-on:paste="handlePaste"
          placeholder="Type your post's text here..."
          maxlength="800"
        >
        </textarea>
        <!-- Not shown in admin post -->
        <!-- This shows in Group link -->
        <div v-if="(group && (creator || moderator)) || (group && admin)" class="post-type">
          <Switcher
            label="Pinned post"
            v-model="model.exposed"
            id="exposePost"
          ></Switcher>
          <button
            type="button"
            v-if="!showTableEditor && !isMainTab && !showStaticTable"
            class="table-edit"
            @click="toggleTableEditor"
          >
            <span>Championship table</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-table"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"
              />
            </svg>
          </button>
          <span class="table-edit-tab" v-if="checkTableData && !isMainTab && showStaticTable">
            <button type="button" class="table-edit" @click="toggleTableEditor">
              <b-icon-pencil-square></b-icon-pencil-square>
            </button>
            <button type="button" class="table-edit" @click="clearTable">
              <b-icon-x></b-icon-x>
            </button>
          </span>
          <c-table-manage-form
            :show="showTableEditor"
            @close="tableEditorClosed()"
            @tableUpdated="updateTableData"
            ref="tableForm"
          ></c-table-manage-form>
        </div>
        <static-table
          v-if="showStaticTable"
          :table-data="tableData"
        ></static-table>
        <a
          v-if="'preview_img' in model.preview"
          :href="model.preview.preview_url"
          class="link-preview"
          target="_blank"
        >
          <img
            :src="model.preview.preview_img"
            :alt="model.preview.preview_img"
          />
          <h4>{{ model.preview.preview_title }}</h4>
          <small>{{ model.preview.preview_url }}</small>
          <b-icon-trash class="trash" @click="clearPreview"></b-icon-trash>
        </a>
        <!-- * - * -->
        <div class="under">
          <ImageUpload 
            @changed="imageChanged" :key="refreshKey + 'img'" />
          <h4 id="counter">{{ model.text.length }}/800</h4>
        </div>

        <!-- Icon -->
        <div ref="emojiPicker" class="emoji-picker-container">
          <div 
            @click="toggleEmojiPicker" 
            class="emoji-picker-toggler"></div>
          <Picker 
            v-show="showEmojiSelector"
            emoji="smile"
            :color="'var(--orange)'"
            :showPreview="false"
            :data="emojiIndex" 
            set="twitter"
            @select="emojiSelected" />
        </div>
      </div>

      <!-- Not in admin, Not in groups -->
      <div class="tags" v-if="!admin && !group">
        <TagSearchComponent
          ref="tagger"
          v-if="ready"
          :tags="tags"
          @selected="selected"
          :selectedTags="model.selectedTags"
        />
      </div>
      <!-- * - * -->
      <span class="response">{{ responseMsg }}</span>
      <div class="pinned__post" v-if="admin">
        <span class="pinned__post--text">
          Pinned post
        </span>
        <Switcher id="adminPost" @input="isAdminPostPinned" :value="isPinned" />
      </div>
      <PinnedPostDatePicker
        v-if="isPinned"
        @getDate="pinnedPostDate"
        @getTime="pinnedPostTime"
      />
      <input type="submit" value="POST" />
    </form>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Switcher from "@/components/Switcher";
import DarkSelect from "@/components/DarkSelect";
import ImageUpload from "@/components/ImageUpload";
import StaticTable from "@/components/StaticTable";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
import CTableManageForm from "@/components/group/CTableManageForm";
import TagSearchComponent from "@/components/tag/TagSearchComponent";
import PinnedPostDatePicker from "@/components/PinnedPostDatePicker";
import { BIconPencilSquare, BIconX, BIconTrash } from "bootstrap-vue";
// twiter emoji sets.
import data from "emoji-mart-vue-fast/data/twitter.json";
// Efan emoji emart css styles
import '../../assets/css/emoji-mart-efan.scss';

let emojiIndex = new EmojiIndex(data, {
  include: ['recent', 'smileys', 'activity'],
  exclude: []
});

export default {
  name: "PostForm",
  components: {
    Picker,
    StaticTable,
    DarkSelect,
    Switcher,
    TagSearchComponent,
    ImageUpload,
    PinnedPostDatePicker,
    CTableManageForm,
    BIconX,
    BIconPencilSquare,
    BIconTrash,
  },
  props: {
    moderator: Boolean,
    admin: {
      default: false,
      type: Boolean,
    },
    group: {
      default: false,
      type: Boolean,
    },
    creator: {
      default: false,
      type: Boolean,
    },
    groupId: {
      default: -1,
      type: Number,
    },
    tabId: {
      default: -1,
      type: Number,
    },
    isMainTab: {
      default: false,
      type: Boolean,
    },
    isAdminOnlyTab: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      model: {
        text: "",
        img_url: "",
        selectedTags: [],
        selectedType: "a",
        postType: "default",
        exposed: false,
        fullExpirationDate: null,
        expirationTime: null,
        expirationDate: null,
        preview: {},
      },
      isPinned: false,
      imgData: false,
      tags: [],
      showImg: false,
      title: "New post",
      responseMsg: "",
      refreshKey: 1,
      ready: false,
      showTableEditor: false,
      showStaticTable: false,
      submitting: false,
      showEmojiSelector: false,
      emojiIndex: emojiIndex,
      tableData: {
        type: Object,
        default() {
          return {
            columns: [],
            rows: [],
            keySorted: {
              key: null,
              type: "DESC",
            },
          };
        },
      },
    };
  },
  mounted() {
    if (!this.admin && !this.group) {
      this.tags = this.$store.getSubscribed();
    } else {
      this.title = "New " + (this.admin ? "admin" : "group") + " post";
    }
    this.ready = true;
  },
  watch: {
    $route(to, from) {
      (this.model.text = ""),
        (this.model.img_url = ""),
        (this.model.selectedTags = []),
        (this.model.selectedType = "a"),
        (this.model.postType = "default"),
        (this.model.exposed = false),
        (this.model.fullExpirationDate = null),
        (this.model.expirationTime = null),
        (this.model.expirationDate = null),
        (this.model.preview = {});
        this.tableData = {
          columns: [],
          rows: [],
          keySorted: {
            key: null,
            type: "DESC",
          },
        };
    },
    showEmojiSelector(value) {
      if(value) {
        setTimeout(() => {
          document.addEventListener('click', this.handleOutsideClick)
        }, 200)
              
      } else {
        document.removeEventListener('click', this.handleOutsideClick)
      }
    }
  },
  methods: {
    handleOutsideClick(event) {
      const emojiPickerSection = this.$refs.emojiPicker.querySelector('section');
      if(emojiPickerSection && !emojiPickerSection.contains(event.target)) {
        this.showEmojiSelector = false;
      }
    },
    toggleEmojiPicker() {
      this.showEmojiSelector = !this.showEmojiSelector;
    },
    emojiSelected(emoji) {
      this.model.text += `${emoji.native}`;
    },
    isAdminPostPinned(val) {
      this.isPinned = val;
      if (!this.isPinned) {
        this.model.expirationTime = null;
        this.model.expirationDate = null;
        this.model.fullExpirationDate = null;
      }
    },
    tableEditorClosed() {
      this.showTableEditor = false;
    },
    pinnedPostDate(date) {
      this.model.expirationDate = date.expiration_date;
    },
    pinnedPostTime(time) {
      this.model.expirationTime = time.expiration_time;
    },
    concatDate() {
      if (
        this.model.expirationDate !== null &&
        this.model.expirationTime !== null
      ) {
        this.model.fullExpirationDate =
          this.model.expirationDate + " " + this.model.expirationTime;
      }
    },
    toggleTableEditor() {
      this.showTableEditor = !this.showTableEditor;
    },
    imageChanged(data) {
      this.imgData = data;
    },
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.submitting) {
        // Upload image if needed
        if (this.imgData !== false) {
          let response = await this.$api.uploadImage(this.imgData, this.userStore.accessToken);

          if (response.success === true) {
            this.model.img_url = response.url;
          }
        }

        this.concatDate();

        if (this.isPinned && this.admin && this.model.fullExpirationDate) {
          this.model.postType = "pinned";
        }

        let messageText = "";
        if (this.checkTableData) {
          messageText = this.stringifyTable(this.model.text);
          this.model.postType = "table";
        } else {
          messageText = this.model.text;
        }

        // Clientside validation
        if (this.model.text.length > 800) {
          this.title = "Post has to contain less than 800 characters.";
          this.responseMsg = "Post has to contain less than 800 characters.";
          return;
        } else if (
          messageText.length === 0 &&
          this.model.img_url.length === 0
        ) {
          this.title = "Post either has to contain text or an image.";
          this.responseMsg = "Post either has to contain text or an image.";
          return;
        } else if (
          !this.admin &&
          !this.group &&
          this.model.selectedTags.length < 1
        ) {
          this.title = "Please select at least one tag.";
          this.responseMsg = "Please select at least one tag.";
          return;
        } else {
          // prevent multiple submits
          this.submitting = true;
          // Upload
          // Convert newlines to <br> tags
          this.model.text = messageText.replace(/\n\r?/g, "<br>");

          // Post form to API
          const response = await this.$api.newPost(
            this.model,
            this.userStore.user.id,
            this.admin,
            this.group,
            this.groupId,
            this.tabId,
            this.isAdminOnlyTab,
            this.userStore.accessToken
          );

          // Reset form
          if (response.success === true) {
            this.model.text = "";
            this.model.img_url = "";
            this.model.preview = {};
            this.model.postType = "default";
            this.model.fullExpirationDate = null;
            this.model.expirationTime = null;
            this.model.expirationDate = null;
            this.isPinned = false;
            this.imgData = false;

            if (this.$refs.tagger) {
              this.$refs.tagger.showLimit = true;
              this.$refs.tagger.resetClicks(this.model.selectedTags);
            }
            this.model.selectedTags = [];
            this.title = "New post";
            this.clearTable(false);
            this.tableData = { // on submit set table data back to default
              columns: [],
              rows: [],
              keySorted: {
                key: null,
                type: "DESC",
              },
            }
            this.showStaticTable = false;

            this.refreshKey++; // Re-renders the ImageUpload component, causing it to reset

            this.$emit("refresh");
            this.submitting = false;
          } else {
            this.title = response.msg;
            this.responseMsg = response.msg;
            this.submitting = false;
          }
        }
      }
    },
    selected(id) {
      let index = this.model.selectedTags.indexOf(id);
      if (index !== -1) this.model.selectedTags.splice(index, 1);
      else this.model.selectedTags.push(id);
    },
    /**
     * Update the local table data from table form.
     * @param data
     */
    updateTableData(data) {
      this.showStaticTable = true;
      this.tableData = data;
    },
    /**
     * Clear the local and form table data.
     */
    clearTable(withConfirm = true) {
      if (this.group && this.$refs.tableForm) {
        if (withConfirm) {
          let clear = confirm("All table data will be deleted. Continue?");
          if (clear) {
            this.$refs.tableForm.clearTable();
            this.showStaticTable = false;        

          }
        } else {
          this.$refs.tableForm.clearTable();
          this.showStaticTable = false;        
        }
      }
    },
    /**
     * Gets the stringified version of the object.
     * @param message
     * @returns {string}
     */
    stringifyTable(message) {
      return JSON.stringify({
        tbl: this.tableData,
        msg: message,
      });
    },
    /**
     * Clear the link preview.
     */
    clearPreview(e) {
      e.preventDefault();
      this.model.preview = {};
    },
    /**
     * Handle the pasted link.
     * @param e
     * @returns {Promise<void>}
     */
    async handlePaste(e) {
      let clipboardData, pastedData;

      // Get pasted data via clipboard API
      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData("Text");

      // Do whatever with pasted data
      // Post form to API
      const response = await this.$api.preview(pastedData);
      if (response.success === true) {
        this.model.preview = response.msg;
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
    rows() {
      let charCount = 56; // How many characters per line
      let newlinesCount = ((this.model.text || "").match(/\n\r?/g) || [])
        .length; // Counts number of newlines in text
      let rows =
        parseInt(this.model.text.length / charCount) + 1 + newlinesCount;
      return Math.min(rows, 14); // Limit it to 12 rows max
    },
    /**
     * Check table state for button display.
     * @returns {boolean}
     */
    checkTableData() {
      let state = true;
      if (!this.tableData.rows) {
        state = false;
      } else if (this.tableData.rows.length === 0) {
        state = false;
      }
      if (!this.tableData.columns) {
        state = false;
      } else if (this.tableData.columns.length === 0) {
        state = false;
      }
      return state;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 20px;

  form {
    padding: 3%;
    background-color: var(--dark);
    border-radius: 15px;

    @include respond-to("small") {
      margin-left: 10px;
      margin-right: 10px;
    }

    input {
      display: block;
      width: 90%;
      margin: 0 auto;
    }

    .response {
      display: inline-block;
      width: 100%;
      color: var(--text);
      text-align: center;
      margin-bottom: 8px;
      font-weight: bold;
    }

    .text {
      position: relative;
      z-index: 1;
      color: var(--text);

      textarea {
        box-sizing: border-box;
        @include scrollbars;
        @include remove-outline;

        display: block;
        height: auto;
        width: 100%;
        @include center;
        padding: 10px;

        text-align: left;
        resize: none;
        overflow: hidden;

        font-size: 1.1em;
        color: var(--text);
        @include placeholder {
          color: var(--light);
        }
        background-color: var(--medium);
        border: none;
        border-radius: 12px;

        &:focus {
          animation: pulse 0.5s;
          background-color: var(--medium);
        }

        margin-bottom: 10px;
      }

      .post-type {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .link-preview {
        display: grid;
        grid-template-columns: 0.3fr 0.7fr;
        grid-template-areas:
          "img title"
          "img link";
        grid-column-gap: 10px;
        text-decoration: none;
        border: 1px solid var(--text);
        padding: 10px 10px 20px;
        border-radius: 10px;
        position: relative;
        img {
          grid-area: img;
          object-fit: cover;
          width: 100%;
          max-height: 100%;
        }
        h4 {
          grid-area: title;
          color: var(--text);
        }
        small {
          grid-area: link;
          color: var(--text);
          font-size: 12px;
        }
        .trash {
          position: absolute;
          top: 12px;
          right: 12px;
          grid-area: trash;
          color: var(--text);
          &:hover {
            color: var(--orange);
          }
        }
      }
      .under {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        #counter {
          font-size: 0.9em;
          color: var(--text);
        }
        h4 {
          margin: 0;
          margin-top: 5px;
        }
      }

      .emoji-picker {
        &-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          position: absolute;
          top: 4px;
          right: 8px;
          padding: 3px;
        }

        &-toggler {
          cursor: pointer;
          border-radius: 50%;
          background-color: var(--medium);
          background-image: url("../../assets/icons/emoji.svg");
          background-repeat: no-repeat;
          width: 25px;
          height: 25px;
          margin-bottom: 10px;
          transition: transform 0.2s ease-in-out;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .tags {
      margin-top: 10px;
    }

    .table-edit {
      @include button(8px, false, var(--medium));
      transition: all 0.1s;
      &:hover {
        background: var(--medium-light);
      }
      &:active {
        background: var(--medium-dark);
      }
      span {
        vertical-align: middle;
        margin-right: 10px;
      }
      svg {
        vertical-align: middle;
      }
    }

    .table-edit-tab {
      background: var(--background-light);
      /*padding-bottom: 2px;*/
      margin-bottom: -2px;
      // margin-left: 3px;
      border-radius: 15px 15px 0 0;

      .table-edit {
        @include button(4px, false, var(--medium-darker));
        padding: 4px 6px;
        line-height: 16px;
      }
    }

    input[type="submit"] {
      @extend .pointer;
      @extend .noselect;
      @extend .nodrag;

      &:hover {
        @include popup(1.05);
      }

      @include gradient;

      width: 30%;
      margin: 0;
      @include center;
      padding: 10px;

      border: none;
      border-radius: 10px;
      font-size: 1.1em;
      color: var(--light);
    }
  }

  .pinned__post {
    display: flex;
    &--text {
      margin-top: 3px;
      color: #fff;
    }
  }
}
</style>
